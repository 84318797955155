import React from 'react';
import styled from 'styled-components';
import { breakpoint, color, typography, width } from '../theme';
import { DefaultWrapper } from '../theme/wrapper';

const Background = styled.div`
  background-color: ${color.grey[15]};
`;

const Wrapper = styled(DefaultWrapper)`
  max-width: ${props => props.fullWidth ? 'initial' : props.containerLarge ? `${width.lg}rem` : `${width.md}rem`};
  width: ${props => props.fullWidth ? '100%' : ''};
`;

const Heading = styled.div`
  text-align: center;
  padding: 6rem 0;

  @media ${breakpoint.md} {
    padding: 8rem 0;
  }
`;

const Title = styled.h1`
  color: ${color.brand[50]};
  text-transform: uppercase;
  font-size: ${typography.size.f7};
  font-weight: bold;
  margin-bottom: 0.25em;
  letter-spacing: ${typography.spacing.wide / 2}em;
`;

const Span = styled.span`
  display: inline-block;
  font-family: ${typography.family.title};
  font-size: ${typography.size.f3};
  font-weight: bold;
  line-height: ${typography.height.none};
  padding: 0 1rem;
  max-width: ${props => props.titleWidth || 8}em;
  margin: 0 auto;
  
  @media ${breakpoint.md} {
    font-size: ${typography.size.f1};
    margin: 0 auto;
  }
`;

const Content = styled.div`

  padding-bottom: ${props => props.fullWidth ? '' : '4rem'};
  
  h2 {
    font-family: ${typography.family.title};
    color: ${color.brand[60]};
    letter-spacing: ${typography.spacing.wide / 2}em;
    font-weight: bold;
    text-transform: uppercase;
    font-size: ${typography.size.f6};
    margin-bottom: 1rem;

    @media ${breakpoint.md} {
      font-size: ${typography.size.f5};
    }
  }
`;

const InfoPageTemplate = ({span, title, containerLarge, fullWidth, titleWidth, children}) => {
  return (
    <Background>
      <Wrapper containerLarge={containerLarge} fullWidth={fullWidth}>
        <Heading>
          <Title>{span}</Title>
          <Span titleWidth={titleWidth}>{title}</Span>
        </Heading>
        <Content fullWidth={fullWidth}>
          {children}
        </Content>
      </Wrapper>
    </Background>
  );
};

export default InfoPageTemplate;

