import React from 'react';
import styled from 'styled-components';
import { breakpoint, color, radius, shadow } from '../theme';

const Wrapper = styled.div`
  background-color: ${color.white};
  padding: 2rem;
  box-shadow: ${shadow.box.page};
  margin-bottom: 2rem;
  border-radius: ${radius.sm};
  min-height: 16rem;

  @media ${breakpoint.md} {
    margin-bottom: 4rem;
  }
`;

const BlankPage = ({children}) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default BlankPage;
