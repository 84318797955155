import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { breakpoint, color } from '../theme';

const Wrapper = styled.aside`
  width: 88%;
  color: ${color.grey[60]};
  font-size: 1rem;

  @media ${breakpoint.sm} {
    font-size: 1.25rem;
    width: 72%;
  }
  @media ${breakpoint.md} {
    font-size: 1.25rem;
    width: 60%;
  }
`;

export const ContactLink = () => {
  return (
    <Wrapper>
      <p>If you haven't found what you were looking for, please <Link to="/contact/">contact us</Link> and we'd be happy to help.</p>
    </Wrapper>
  );
};
